.developmentpage {
  background: $dark;
}

.development-hero {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 350px;
  margin: 40px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black, .6);
  }

  @media (max-width: 1023px) {
    height: 250px;
  }

  .hero-container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 100%;
  }

  .hero-title {
    margin: 0;
    font-size: 50px;
    line-height: 130%;
    color: $white;

    @media (max-width: 1023px) {
      font-size: 46px;
    }

    @media (max-width: 599px) {
      font-size: 38px;
    }
  }

}

.development-blocks {
  position: relative;
  padding: 50px 100px 100px;

  @media (max-width: 767px) {
    padding: 30px 40px 50px;
  }

  @media (max-width: 499px) {
    padding: 30px 20px 50px;
  }

  .blocks-item {
    display: flex;
    margin-bottom: 30px;

    @media (max-width: 1199px) {
      flex-direction: column;
      margin-bottom: 50px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .item-content {
      width: 60%;
      margin-right: 10%;
      color: $white;

      @media (max-width: 1199px) {
        width: 100%;
        margin-right: 0;
      }

      h1 {
        font-size: 46px;
        color: $colorAccent1;

        @media (max-width: 899px) {
          font-size: 34px;
        }
      }

      p {
        font-size: 22px;

        @media (max-width: 899px) {
          font-size: 18px;
        }
      }

      strong {
        font-weight: 900;
      }
    }

    .item-visual {
      width: 40%;
      display: flex;
      align-items: center;

      @media (max-width: 1199px) {
        justify-content: center;
        width: 100%;
        padding-top: 50px;
      }

      img {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
      }

      iframe {
        width: 100%;

        @media (max-width: 1023px) {
          height: 500px;
        }

        @media (max-width: 699px) {
          height: 400px;
        }

        @media (max-width: 499px) {
          height: 250px;
        }
      }
    }
  }
}
