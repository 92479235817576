.pdfspage {
  background: $dark;
}

.pdfs-container {
  padding-top: 50px;
  padding-bottom: 80px;
}

.pdfs-wrapper{
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .pdfs-content {
    width: calc(65% - 50px);
    margin-right: 100px;

    @media (max-width: 899px) {
      order: 2;
      width: 100%;
      margin-right: 0;
      margin-bottom: 50px;
    }

    h1 {
      color: $colorAccent1;
      font-size: 38px;

      @media (max-width: 899px) {
        font-size: 32px;
      }
    }

    p,
    ul li {
      margin: 0;
      font-size: 22px;
      line-height: 130%;
      font-weight: 400;
      color: $white;

      @media (max-width: 899px) {
        font-size: 18px;
      }
    }

    b,
    strong {
      font-weight: 700;
    }

    .ratings-container {
      display: flex;
      align-items: center;
      margin: 40px 0;

      @media (max-width: 599px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .ratings-label {
      margin-right: 40px;
      font-size: 26px;
      font-weight: 600;
      color: $white;

      @media (max-width: 599px) {
        margin-bottom: 20px;
      }
    }

    .rating {
      border: none;
      float: left;
    }

    .rating > span:before {
      margin: 5px;
      font-size: 28px;
      font-family: 'fontello';
      display: inline-block;
      content: "\E812";
  }

    .rating > .half:before {
      content: "\E82D";
      position: absolute;
      opacity: 0;
    }

    .rating > span {
      position: relative;
      color: $colorAccent1;
      float: right;

      &.is-active {
        color: $colorAccent1;

        &.full:before {
          content: "\E810";
        }

        &.half:before {
          opacity: 1;
        }
      }
    }

    .reviews-container {
      display: flex;
      flex-direction: column;
    }

    .reviews-item {
      display: flex;
      align-items: stretch;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid $white;

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      @media (max-width: 599px) {
        flex-direction: column;
      }

      .item-infos {
        width: 100%;
        max-width: 150px;

        @media (max-width: 599px) {
          max-width: 100%;
          margin-bottom: 15px;
        }
      }

      .item-description {
        padding-left: 40px;

        @media (max-width: 599px) {
          padding-left: 0;
        }
      }

      p {
        font-size: 18px;
      }
    }
  }

  .pdfs-visual {
    width: calc(35% - 50px);

    @media (max-width: 899px) {
      order: 1;
      width: 100%;
    }

    .pdfs-cover {
      position: relative;
    }

    .pdfs-price {
      position: absolute;
      top: -42px;
      right: -42px;
      width: 115px;
      height: 115px;
      border-radius: 50%;
      border: 3px solid #008000;
      background: $white;

      span {
        position: absolute;
        top: 50%;
        left: 50%;
        color: #008000;
        font-family: 'Arial';
        font-size: 48px;
        font-weight: 700;
        transform: translate(-50%, -50%);
      }

    }

    img {
      display: block;
      width: 100%;
    }
  }

  .pdfs-buy {
    margin-top: 30px;

    .pdfFullname,
    .pdfCompany {
      margin-bottom: 30px;
    }

    .site-btn {
      width: 100%;
    }
  }
}

.pdfs-payment-details {
  .payment-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $white;

    svg {
       width: 28px;
      margin-right: 10px;
    }

    path {
      fill: $light;
    }
  }

  .payment-cards-method {
    font-size: 16px;
    color: $white;
  }

  .payment-cards-icons {
    background: $lighter;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 3px;
  }
}

.pdfs-faq {
  max-width: 900px;
  margin: 100px auto 0;

  @media (max-width: 899px) {
    margin: 0 auto;
  }

  .pdfs-faq-title {
    margin: 0 auto 50px;
    font-size: 50px;
    color: $white;
    font-weight: 600;
    text-align: center;

    @media (max-width: 899px) {
      font-size: 40px;
    }
  }

  .faq-item {
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
    padding: 15px 20px;
    border-radius: 6px;
    border: 1px solid $white;
    font-size: 22px;
    color: $white;
    font-weight: 500;
    transition: .4s all ease;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 899px) {
      font-size: 18px;
    }

    &.is-active {
      .item-header {
        position: relative;
        cursor: pointer;

        &::after {
          transform: translateY(-50%) rotate(-90deg);
        }
      }

      .item-content {
        max-height: 100vh;
      }
    }

    .item-header {
      position: relative;
      cursor: pointer;

      &::after {
        content: '\E826';
        position: absolute;
        top: 50%;
        right: 10px;
        font-family: fontello;
        font-size: 16px;
        transform: translateY(-50%) rotate(90deg);
        transition: .4s all ease;
      }

      span {
        width: 100%;
        padding-right: 20px;
      }
    }

    .item-content {
      position: relative;
      overflow: hidden;
      width: 100%;
      max-height: 0;
      font-size: 18px;
      line-height: 150%;
      transition: .6s all ease;

      @media (max-width: 899px) {
        font-size: 16px;
      }

      .content-separator {
        width: 100%;
        height: 1px;
        margin: 15px auto;
        background: $white;
      }

      p {
        margin: 0 0 10px;

        &:last-of-type {
          margin: 0;
        }
      }

      strong {
        color: $colorAccent1;
        font-weight: 600;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}
