// --------------------------------------------------
// Variables
// --------------------------------------------------

$fontTitle: 'Arial', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontTitleSerif: 'Arial', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontText: 'Arial', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
$fontTextLight: 'Arial', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';

// Accents Colors
$colorAccent1: #AD0E16;
$colorAccent2: #939494;
$colorAccent3: #164C70;
$colorAccent4: #164C70;

// Texts
$colorTextDark: #3F4040;
$colorTextRegular: #3F4040;
$colorTextLight: #fff;

// Blocks and Text
$black: #000;
$dark: #121316;
$grey: #F8F8F8;
$light: #D3D3D3;
$lighter: #F8F8F8;
$alternateWhite: #F8F8F8;
$white : #fff;


















//Modules Colors
$colorM1: #13AEEE ;
$colorM2: #13AEC3 ;
$colorM3:#13AE87;
$colorM4:#4FD3B0;
$colorM5:#4FD389;
$colorM6:#4FD360;
$colorM7:#FE874E;
$colorM8:#FE8777;
$colorM9:#FE87BE;
$colorM10:#CF065A;
$colorM11:#CF068E;
$colorM12:#CF06C8;
$colorM13:#845AB8;
$colorM14:#845AD8;
$colorM15:#845AF6;
