// --------------------------------------------------
// Title
// --------------------------------------------------

b,
strong {
  font-weight: 500;
}

p a {
  color: $colorAccent1;
  text-decoration: underline;
}

.big-title {
  position: relative;
  width: 100%;
  margin: 0;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 140%;
  text-transform: uppercase;
  color: $colorAccent1;

  @media (max-width: 1024px) {
    font-size: 40px;
  }

  @media (max-width: 768px) {
    font-size: 32px;
  }

  strong {
    font-weight: 900;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $colorAccent2;
  }
}

.fonts-loaded .big-title {
  font-family: $fontTextLight;
}

.medium-title {
  margin: 0;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 140%;
  text-transform: uppercase;
  word-break: break-word;
  color: $colorAccent1;

  @media (max-width: 1024px) {
    font-size: 28px;
  }

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.fonts-loaded .medium-title {
  font-family: $fontTitleSerif;
}

.small-title {
  margin: 0;
  font-family: $fontTitle;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 140%;
  text-transform: uppercase;
  color: $colorAccent1;

  @media (max-width: 1024px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }

  strong {
    font-weight: 900;
  }
}

.fonts-loaded .small-title {
  font-family: $fontTitle;
}

// --------------------------------------------------
// text-emphasis: dot;
// --------------------------------------------------

.regular-text {
  font-family: $fontText;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;

  @media (max-width: 1024px) {
    font-size: 16px;
    line-height: 140%;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  strong {
    font-weight: 600;
    color: #3F4040;
  }

  a {
    font-weight: 600;
    color: $colorAccent1;
    transition: all 0.35s ease;

    &:hover {
      color: $colorAccent2;
    }
  }

  &.is-centered {
    width: 100%;
    text-align: center;
    letter-spacing: 0.035em;
    font-size: 18px;
  }
}

.medium-text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.8;

  @media only screen and (max-width: 1099px) {
    font-size: 16px;
    line-height: 1.5;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }

  strong {
    font-weight: 700;
  }
}

.text-input {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 22px;
  color: $dark;

  @media screen and (min-width: 1440px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.small-text {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.8;
  color: #103A52;

  @media only screen and (max-width: 1099px) {
    font-size: 16px;
    line-height: 1.5;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.links {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.29px;
  line-height: 14px;
  color: $colorAccent2;

  @media screen and (min-width: 1440px) {
    font-size: 24px;
    line-height: 16px;
  }
}

.button-text {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.27px;
  line-height: 11px;

  @media screen and (min-width: 1440px) {
    font-size: 13px;
    line-height: 13px;
  }
}

.pagination {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 14px;
  color: $dark;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// --------------------------------------------------
// Extra
// --------------------------------------------------

.light-typo {
  color: #fff;
}

.lightblue-typo {
  color: $colorAccent2;
}

.centered {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}

ul {
  li {
    line-height: 2;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      line-height: 2;
    }
  }
}
