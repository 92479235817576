// --------------------------------------------------
// Btns components
// --------------------------------------------------
.site-btn {
  display: inline-block;
  padding: 15px 25px;
  border: 1px solid $colorAccent1;
  background: rgba($colorAccent1, 1);
  color: $white;
  font-family: $fontText;
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  transition: .3s;
  transition-duration: .3s;
  border-radius: 3px;
  -webkit-appearance: none;

  &:hover {
    background: rgba($colorAccent1, .9);
  }

  @media (max-width: 899px) {
    font-size: 16px;
  }

  i {
    font-size: 24px;
    margin-right: 8px;
  }
}
