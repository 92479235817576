.trainingpage {
  background: $dark;
}

.training-breadcrumb {
  margin-top: 40px;

  .breadcrumb-content {
    color: $white;
    font-size: 20px;

    @media (max-width: 767px) {
      font-size: 18px;
    }

    @media (max-width: 599px) {
      font-size: 16px;
    }
  }
}

.training-hero {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 350px;
  margin: 20px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba($black, .6);
  }

  @media (max-width: 1023px) {
    height: 250px;
  }

  .hero-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .hero-title {
    margin: 0;
    font-size: 50px;
    line-height: 130%;
    color: $white;

    @media (max-width: 1023px) {
      font-size: 46px;
    }

    @media (max-width: 599px) {
      font-size: 38px;
    }
  }

  .hero-text {
    margin: 10px 0 0;
    font-size: 32px;
    line-height: 130%;
    font-weight: 400;
    color: $white;

    @media (max-width: 1023px) {
      font-size: 26px;
    }

    @media (max-width: 599px) {
      font-size: 20px;
    }
  }

}

.training-container {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 100px;

  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    padding-bottom: 50px;
  }

  .training-title {
    margin: 0 0 5px;
    font-size: 38px;
    font-weight: 600;
    line-height: 130%;
    color: $white;

    @media (max-width: 899px) {
      font-size: 34px;
    }
  }

  .training-row {
    width: 100%;
    margin-bottom: 50px;
  }

  .training-objectives,
  .training-who-is-for,
  .training-advantages {
    padding: 15px;
    border :1px solid $white;
    background: #191c21;
  }

  .content-group {
    display: flex;
    margin-bottom: 50px;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .training-row {
      width: calc(50% - 10px);
      margin-bottom: 0;

      &:first-of-type {
        margin-right: 20px;
      }

      @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;

        &:first-of-type {
          margin-bottom: 50px;
        }
      }
    }
  }

  .training-content {
    width: calc(65% - 25px);
    margin-right: 50px;

    @media (max-width: 1023px) {
      width: 100%;
      margin-right: 0;
    }

    p,
    ul li {
      margin: 0;
      font-size: 22px;
      line-height: 130%;
      font-weight: 400;
      color: $white;

      @media (max-width: 899px) {
        font-size: 18px;
      }
    }

    .details-item {
      margin-bottom: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid $colorAccent1;

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    .item-title {
      margin: 0;
      font-size: 28px;
      font-weight: 600;
      line-height: 130%;
      color: $white;

      @media (max-width: 899px) {
        font-size: 24px;
      }
    }
  }

  .training-sidepanel {
    width: calc(35% - 25px);
    padding: 20px;
    padding-left: 50px;

    @media (max-width: 1023px) {
      width: 100%;
      margin-bottom: 30px;
    }

    .sidepanel-container {
      display: flex;
      flex-direction: column;

      @media (max-width: 1023px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }

    button {
      margin: 0;
      padding: 0;
      border: none;
      background: transparent;
      cursor: pointer;

      &:focus {
        outline: 0;
      }
    }

    .sidepanel-item {
      display: flex;
      align-items: center;
      margin-bottom: 25px;

      @media (max-width: 1023px) {
        margin-bottom: 20px;
        margin-right: 20px;

        &:last-of-type {
          margin-bottom: 20px;
        }
      }

      .item-icon {
        width: 42px;
        margin-right: 15px;

        svg {
          width: 42px;
        }

        path {
          fill: $white;
        }
      }

      .item-label {
        font-size: 18px;
        font-weight: 500;
        color: $white;

        @media (max-width: 899px) {
          font-size: 16px;
        }

        strong,
        b {
          font-weight: 700;
        }
      }
    }
  }

  .register-form {
    width: 100%;
    margin: 30px auto 0;

    .form-group {
      margin-bottom: 30px;
    }

    .site-btn-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}
